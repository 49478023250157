import { useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { CandidacyAwardFinalPosition, Order } from '@/enums';
import { setCandidacy } from '@/redux/awards/slice';
import { stableSort } from '@/utils';

import { CandidacyStatus } from './types';
import type { IAwardPropositionTableProps } from './types';
import type { ChangeEvent } from 'react';
import type { ICandidacyResponse } from '@/dto';

const useAwardPropositionTable = ({ candidacies }: IAwardPropositionTableProps) => {
  const [order, setOrder] = useState<Order>(Order.Asc);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const dispatch = useDispatch();

  const handleSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === Order.Asc;
      setOrder(isAsc ? Order.Desc : Order.Asc);
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleChangePage = useCallback((_event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleSelectRow = useCallback(
    (id: string | null) => () => {
      dispatch(setCandidacy({ candidacyId: id }));
    },
    [dispatch],
  );

  const getStatus = useCallback((candidacy: ICandidacyResponse) => {
    if (candidacy.refusal.length) {
      return CandidacyStatus.DECLINE;
    }

    if (
      !!candidacy.awardResults?.find(
        ({ finalPosition }) => finalPosition === CandidacyAwardFinalPosition.WINNER,
      )
    ) {
      return CandidacyStatus.WINNER;
    }

    return candidacy.awardResults.length > 0 ? CandidacyStatus.INTRODUCED : CandidacyStatus.ACTIVE;
  }, []);

  const candidaciesWithStatus = useMemo(
    () => candidacies.map((item) => ({ ...item, status: getStatus(item) })),
    [candidacies, getStatus],
  );

  const rows = useMemo(
    () =>
      stableSort(candidaciesWithStatus, { order, orderBy }).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [candidaciesWithStatus, order, orderBy, page, rowsPerPage],
  );
  return {
    order,
    orderBy,
    rows,
    rowsPerPage,
    page,
    handleChangeRowsPerPage,
    handleChangePage,
    handleSort,
    handleSelectRow,
  };
};

export default useAwardPropositionTable;
