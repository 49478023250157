import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { PAGE_LIMIT } from '@/constants';
import { editorialState } from '@/redux/editorial/selectors';
import { setLoading } from '@/redux/editorial/slice';
import { getEditorialSections } from '@/redux/editorial/thunks';
import { generalInfoState, stepState } from '@/redux/purchasing';
import { setGeneralInfo, setStep } from '@/redux/purchasing/slice';
import { usersRetailerState } from '@/redux/user/selectors';

import type { IGeneralInfo } from '@/redux/purchasing';

const useGeneralInformationForm = () => {
  const dispatch = useDispatch();
  const generalInfo = useSelector(generalInfoState);
  const activeStep = useSelector(stepState);

  const { loading: isLoading, editorialSections } = useSelector(editorialState);
  const retailerData = useSelector(usersRetailerState);

  const editorialSectionsOptions = useMemo(
    () => editorialSections.map(({ id, name }) => ({ value: id, label: name })),
    [editorialSections],
  );

  const renewData = useDebouncedCallback((value: string) => {
    dispatch(getEditorialSections({ first: PAGE_LIMIT, filter: { name: value } }));
  }, 400);

  const onEditorialInputChange = useCallback(
    (value) => {
      dispatch(setLoading(true));
      renewData(value);
    },
    [dispatch, renewData],
  );

  const initialValues: IGeneralInfo = useMemo(
    () =>
      generalInfo ?? {
        productType: '',
        editorySectionId: null,
        brandType: null,
        pricePosition: null,
      },
    [generalInfo],
  );

  const onSubmit = useCallback(
    (value: IGeneralInfo) => {
      dispatch(setGeneralInfo(value));
      dispatch(setStep(activeStep + 1));
    },
    [dispatch, activeStep],
  );

  return {
    initialValues,
    retailerData,
    onSubmit,
    onEditorialInputChange,
    isLoading,
    editorialSectionsOptions,
  };
};

export default useGeneralInformationForm;
