import type { ICandidacyResponse } from '@/dto';

export interface IAwardPropositionTableProps {
  candidacies: ICandidacyResponse[];
}

export enum CandidacyStatus {
  'DECLINE' = 'Decline',
  'WINNER' = 'Winner',
  'INTRODUCED' = 'Introduced',
  'ACTIVE' = 'Active',
}
